import React from 'react';
import { Outlet } from 'react-router-dom';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import MetaTags from 'react-meta-tags';

import GetPL from '../api/getPL';

const mapStateToProps = (state) => {
    return { assetItem: state.asset.assetItem };
}

const mapDispatchToProps = (dispatch) => {
    return{
        changeAsset: ( val ) => dispatch({ type: "CHANGEASSET", payload: val })
    }
}

class PagePL extends React.Component{
	constructor(props){
		super(props)
		this.state = { renderItems: <div></div>, renderItemsArr: [], slidePosition: "-500px", elementCount: 0, mouseDown: false, initMousePos: 0, mouseDifference: 0, selectedAsset: 0, selectedId: 0, startTime: Date.now(), endTime: Date.now() + 1000 }
		this.mouseDown = this.mouseDown.bind(this);
		this.mouseUp = this.mouseUp.bind(this);
		this.mouseMove = this.mouseMove.bind(this);
		this.mouseLeave = this.mouseLeave.bind(this);
		this.touchDown = this.touchDown.bind(this);
		this.touchUp = this.touchUp.bind(this);
		this.touchMove = this.touchMove.bind(this);
		this.touchLeave = this.touchLeave.bind(this);
	}
	
	adjustPosition( index ){
		let position = -152 * index - 500;
		let maxLen = -152 * this.state.elementCount - 500
		
		if( position > -500 )
			position = -500
		if( position < maxLen )
			position = maxLen
		
		this.setState({ slidePosition: position + "px" });
	}
	
    getItems(){
        let items = new GetPL();
        return items.getData;
    }
	
	mouseDown( event ){
		this.setState({ mouseDown: true, initMousePos: event.screenX });
	}
	
	mouseUp( event ){
		this.setState({ mouseDown: false, mouseDifference: 0 });
		if( this.state.mouseDifference > 5 || this.state.mouseDifference < -5 ){
			this.changeSelectedAsset( -Math.floor( this.state.mouseDifference / 152 ) + this.state.selectedId, 0, true );
		}
	}
	
	mouseMove( event ){
		if( this.state.mouseDown == true ){
			let mouseDifference = event.screenX - this.state.initMousePos;
			this.adjustPosition( -Math.floor( mouseDifference / 152 ) + this.state.selectedId );
			this.setState({ mouseDifference: mouseDifference });
			
		}
	}
	
	mouseLeave(){
		this.setState({ mouseDown: false, mouseDifference: 0 });
	}
	
	touchDown( event ){
		this.setState({ mouseDown: true, initMousePos: event.touches[0].clientX, startTime: Date.now() });
	}
	
	touchUp( event ){
		this.setState({ mouseDown: false, mouseDifference: 0 });
		if( this.state.mouseDifference > 5 || this.state.mouseDifference < -5 ){
			let momentum = 1
			if(Date.now() - this.state.startTime < 1000)
			this.changeSelectedAsset( -Math.floor( this.state.mouseDifference / 152 ) * 2 + this.state.selectedId, 0, true );
		}
	}
	
	touchMove( event ){
		if( this.state.mouseDown == true ){
			let mouseDifference = event.touches[0].clientX - this.state.initMousePos;
			this.adjustPosition( -Math.floor( mouseDifference / 152 ) + this.state.selectedId );
			this.setState({ mouseDifference: mouseDifference });
			
		}
	}
	
	touchLeave(){
		this.setState({ mouseDown: false, mouseDifference: 0 });
	}
	
	updateRenderItems( assetId ){
		this.setState({ 
			renderItems : 
				<div>
						{
							Object.keys(this.state.renderItemsArr).map((key, index) => {
								return (
									<li key={ index } className={ (this.state.renderItemsArr[key]['asset_id'] == assetId) ? "active noselect" : "noselect" }>
										<Link to={ "/pl/" + this.state.renderItemsArr[key]['target'] } data-index={ index } onMouseUp={ () => { this.changeSelectedAsset( index, this.state.renderItemsArr[key]['asset_id'] ) }} draggable="false"><img src={ "//tjtrice.co.uk/images/" + this.state.renderItemsArr[key]['preview_image'] } loading="lazy" draggable="false" /></Link>
									</li>
								);
							})
						}
				</div>,
		});
	}
	
	changeSelectedAsset( id, assetId, sliding = false ){
		if( this.state.mouseDifference < 5 && this.state.mouseDifference > -5 ){
			this.adjustPosition( id ); 
			this.props.changeAsset( this.state.renderItemsArr[id] );
			this.updateRenderItems( assetId );
			this.setState({ selectedId: id });
		} else if ( sliding == true ){
			this.adjustPosition( id ); 
			this.setState({ selectedId: id });
		}
	}
	
	setInitialSelected( target, index, asset ){
		let currPath = (window.location.pathname).match(/[a-z-0-9]{1,}/g);

		if( currPath[1] == target ){
			this.adjustPosition( index );
			this.props.changeAsset( asset );
			return true;
		} else {
			return false;
		}
	}
	
	componentDidMount(){
        let items = new Promise((resolve, reject) => { 	
			resolve( this.getItems() ) 
		});
		
		items
        .then( items => { this.setState({ 
			renderItems : 
				<div>
						{
							Object.keys(items).map((key, index) => {
								return (
									<li key={ index } className={ ( this.setInitialSelected( items[key]['target'], index, items[key] ) ) ? "active noselect" : "noselect" }>
										<Link to={ "/pl/" + items[key]['target'] } data-index={ index } onMouseUp={ () => { this.changeSelectedAsset( index, items[key]['asset_id'] ) }} draggable="false" ><img src={ "//tjtrice.co.uk/images/" + items[key]['preview_image'] } loading="lazy" draggable="false" /></Link>
									</li>
								);
							})
						}
				</div>,
			renderItemsArr: items,
			selectedAsset: items[0]['asset_id'],
			elementCount: Object.keys(items).length
			});
		})
		.catch( err => { console.log(err) });		
	}
    
    render(){
        return(
            <div className="page-container">
				<MetaTags>
					<title>Personal Coding Experience | Web Developer Portfolio 2022</title>
					<meta id="meta-description" name="description" content={ "View all personal development experience." } />
					<meta id="og-title" property="og:title" content="Personal Coding Experience | Web Developer Portfolio 2022" />
				</MetaTags>
				<ul className="sub-menu sub-menu-slider" style={{ marginLeft: this.state.slidePosition }} 
					onMouseDown={ this.mouseDown } onMouseUp={ this.mouseUp } onMouseMove={ this.mouseMove } onMouseLeave={ this.mouseLeave }
					onTouchStart={ this.touchDown } onTouchEnd={ this.touchUp } onTouchMove={ this.touchMove } onTouchCancel={ this.touchLeave }
				>
					{ this.state.renderItems }
				</ul>
				<Outlet />
            </div>
        );
    }
}

const ConnectedPagePL = connect( mapStateToProps, mapDispatchToProps )(PagePL)

export default ConnectedPagePL;