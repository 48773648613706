import React from 'react';
import { connect, useSelector } from 'react-redux';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

class About extends React.Component{    
	constructor( props ){
		super(props);
	}

    render(){
        return (
                <div className="standard-content-container about-page">
					<MetaTags>
						<title>About | Tom Rice Portfolio 2022</title>
						<meta id="meta-description" name="description" content="About me" />
						<meta id="og-title" property="og:title" content="About | Tom Rice Portfolio 2022" />
					</MetaTags>
					<p>My name is Tom Rice, a 2017 graduate with a 2:1 in Mathematics from the University of Portsmouth with a history of coding in my free time for both personal and commercial purposes which also complimented my studies. I have also now been involved professionally in web for over 2 years. You can see me on LinkedIn by <Link to="https://www.linkedin.com/in/tom-rice-154840151/" target="_blank">clicking here</Link>.</p>

					<p>This site is currently a work in progress. If you see any errors, do let me know!</p>
					
					<p>
						To do:
						<ul>
							<li>Things to do...</li>
						</ul>
					</p>
                </div>
        );
    }
}

export default About;