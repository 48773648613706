import logo from './logo.svg';
import './App.css';
import React from 'react';
import store from './redux/store';
import { Provider, connect, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

import Menu from './menus/primaryMenu';

import Home from './pages/home.js';
import Options from './pages/options.js';
import About from './pages/about.js';
import Contact from './pages/contact.js';
import PagePA from './pages/pa.js';
import PagePL from './pages/pl.js';
import PagePC from './pages/pc.js';
import SubPagePA from './pages/pa-sub.js';
import SubPagePL from './pages/pl-sub.js';
import SubPagePC from './pages/pc-sub.js';
import BrokenPage from './pages/broken-page.js';

const mapStateToProps = (state) => {
    return { selectedTheme: state.theme.selectedTheme };
}

class AppContents extends React.Component{
	constructor(props){
		super(props)
	}
	
	renderSunImage(){
		return (
			<img src="//tjtrice.co.uk/images/site/testsun5.png" className="sun-image" />
		);
	}
	
	render(){
		return (
			<div className={ this.props.selectedTheme + " test"}>
				{ ( this.props.selectedTheme == "light-standard" ) ? this.renderSunImage() : "" }
				<Router>
					<Menu />
					<Routes>
						<Route path='' element={<Home />} />
						<Route path='/op' element={<Options />} />
						<Route path='ab' element={<About />} />
						<Route path='ct' element={<Contact />} />
						<Route path='pa' element={<PagePA />} >
							<Route path=':assetId' element={<SubPagePA />} />
						</Route>
						<Route path='pl' element={<PagePL />} >
							<Route path=':assetId' element={<SubPagePL />} />
						</Route>
						<Route path='pc' element={<PagePC />} >
							<Route path=':assetId' element={<SubPagePC />} />
						</Route>
						<Route path='*' element={<BrokenPage />} />
					</Routes>
				</Router>
			</div>
		);
	}
}

const ConnectedApp = connect( mapStateToProps )(AppContents)

function App() {
  return (
    <Provider store={store}>
       <ConnectedApp />
	</Provider>
  );
}


export default App;
