import React from 'react';
import { connect, useSelector } from 'react-redux';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

class Contact extends React.Component{    
	constructor( props ){
		super(props);
	}

    render(){
        return (
                <div className="standard-content-container contact-page">
					<MetaTags>
						<title>Contact | Tom Rice Portfolio 2022</title>
						<meta id="meta-description" name="description" content="Contact me" />
						<meta id="og-title" property="og:title" content="Contact | Tom Rice Portfolio 2022" />
					</MetaTags>
					<p>E-mail: tom.j.t.rice@googlemail.com</p>
					<p>Mobile: +447908064940</p>
					<p><Link to="https://www.linkedin.com/in/tom-rice-154840151/" target="_blank">LinkedIn</Link></p>
                </div>
        );
    }
}

export default Contact;