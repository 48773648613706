import defaultAPI from './defaultAPI';

class getMenu extends defaultAPI{
    constructor(targetPathInput = null, dataInput = null){   
        super( targetPathInput, dataInput );
    }
    
    get getMenu() {
        return this.DummyData();
    }

    DummyData(){
        this.RequestData();
        return [
			{ 'menuId': 1, 'subMenuId': 2, 'menuText': 'Home', 'menuTarget': '/' },
            { 'menuId': 1, 'subMenuId': 2, 'menuText': 'Professional Coding', 'menuTarget': '/pc' },
            { 'menuId': 1, 'subMenuId': 2, 'menuText': 'Personal Coding', 'menuTarget': '/pl' },
			{ 'menuId': 1, 'subMenuId': 1, 'menuText': 'Pixel Art', 'menuTarget': '/pa' },
            { 'menuId': 1, 'subMenuId': 2, 'menuText': 'About', 'menuTarget': '/ab' },
            { 'menuId': 1, 'subMenuId': 2, 'menuText': 'Contact', 'menuTarget': '/ct' },
            { 'menuId': 1, 'subMenuId': 2, 'menuText': 'Options', 'menuTarget': '/op' },
        ];
    }
}

export default getMenu;