import React from 'react';
import { useParams } from "react-router-dom";
import { connect, useSelector } from 'react-redux';
import MetaTags from 'react-meta-tags';

import PagePA from './pa';

const mapStateToProps = (state) => {
    return { assetItem: state.asset.assetItem };
}

class SubPagePA extends React.Component{
	constructor(props){
		super(props)
	}
	
    render(){
        return(
            <div className="description-box description-box-pa">
				<MetaTags>
					<title>{ this.props.assetItem['name'] + " | Tom Rice Portfolio 2022" }</title>
					<meta id="meta-description" name="description" content={ this.props.assetItem['name'] + " pixel art piece." } />
					<meta id="og-title" property="og:title" content={ this.props.assetItem['name'] } />
					<meta id="og-image" property="og:image" content={ "//tjtrice.co.uk/images/" + this.props.assetItem['primary_content'] } />
				</MetaTags>
				<div className="container">
					<div className="image-container">
						<img src={ "//tjtrice.co.uk/images/" + this.props.assetItem['primary_content'] } />
					</div>
				</div>
				<div className="container">
					<div>{ this.props.assetItem['name'] }</div>
					<div>{ this.props.assetItem['date'] }</div>
					<div>{ this.props.assetItem['description'] }</div>
					<a href={ "//tjtrice.co.uk/images/" + this.props.assetItem['primary_content'] } target="_blank">Open image in a new tab</a>
				</div>
            </div>
        );
    }
}

const ConnectedSubPagePA = connect( mapStateToProps )(SubPagePA)

export default ConnectedSubPagePA;