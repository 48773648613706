import { createStore } from "redux";
import { combineReducers } from "redux";
import menuReducer from "./reducers/menuReducer";
import assetReducer from "./reducers/assetReducer";
import themeReducer from "./reducers/themeReducer";
//import userReducer from "./reducers/userReducer";

const allReducers = combineReducers({
   menu: menuReducer, 
   asset: assetReducer, 
   theme: themeReducer, 
});

//const store = createStore(userReducer);
const store = createStore(allReducers);

export default store;

