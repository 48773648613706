import React from 'react';
import { connect, useSelector } from 'react-redux';
import { BrowserRouter as Router, Link } from 'react-router-dom';

import getMenu from '../api/getMenu';

const mapStateToProps = (state) => {
    return { primaryMenuItem: state.menu.primaryMenuItem };
}

const mapDispatchToProps = (dispatch) => {
    return{
        changePrimaryMenu: ( val ) => dispatch({ type: "CHANGEPRIMARYMENU", payload: val })
    }
}

class Menu extends React.Component{    
	initSliderSetVar = false;

	constructor( props ){
		super(props);
		this.state = { menuGot: "", initMenuSelected: "", initSliderSet: false, menuClickedOnce: false, menuTop: "20px", slidePosition: 0, mouseDown: false, initMousePos: 0, mouseDifference: 0, selectedAsset: 0, selectedId: 0, startTime: Date.now(), endTime: Date.now() + 1000 }
		this.mouseDown = this.mouseDown.bind(this);
		this.mouseUp = this.mouseUp.bind(this);
		this.mouseMove = this.mouseMove.bind(this);
		this.mouseLeave = this.mouseLeave.bind(this);
		this.hideMenu = this.hideMenu.bind(this);
		this.showMenu = this.showMenu.bind(this);
		this.touchDown = this.touchDown.bind(this);
		this.touchUp = this.touchUp.bind(this);
		this.touchMove = this.touchMove.bind(this);
		this.touchLeave = this.touchLeave.bind(this);
	}

	componentDidMount(){
		var menuClass = new getMenu( 'test', { 'menuId' : 1 } );
        this.setState({ menuGot: menuClass.getMenu });
		
		let currParent = (window.location.pathname).match(/[a-z]{1,}/g);
		if(currParent == null){
			this.setState({ initMenuSelected: "/" });
		} else {
			this.setState({ initMenuSelected: "/" + currParent[0] });
		}
	}
	
	adjustPosition( index ){
		let position = -152 * index - 500;
		let maxLen = -152 * 6 - 500
		
		if( position > -500 )
			position = -500
		if( position < maxLen )
			position = maxLen
		
		this.setState({ slidePosition: position + "px" });
	}
	
	changePrimaryMenu( val ){
		console.log('change menu ' + val);
		this.props.changePrimaryMenu( val );
	}
	
	mouseDown( event ){
		this.setState({ mouseDown: true, initMousePos: event.screenX });
	}
	
	mouseUp( event ){
		this.setState({ mouseDown: false, mouseDifference: 0 });
		if( this.state.mouseDifference > 5 || this.state.mouseDifference < -5 ){
			this.changeSelectedAsset( -Math.floor( this.state.mouseDifference / 152 ) + this.state.selectedId, 0, true );
		}
	}
	
	mouseMove( event ){
		if( this.state.mouseDown == true ){
			let mouseDifference = event.screenX - this.state.initMousePos;
			this.adjustPosition( -Math.floor( mouseDifference / 152 ) + this.state.selectedId );
			this.setState({ mouseDifference: mouseDifference });
		}
	}
	
	mouseLeave(){
		this.setState({ mouseDown: false, mouseDifference: 0 });
	}
	
	touchDown( event ){
		this.setState({ mouseDown: true, initMousePos: event.touches[0].clientX, startTime: Date.now() });
	}
	
	touchUp( event ){
		this.setState({ mouseDown: false, mouseDifference: 0 });
		if( this.state.mouseDifference > 5 || this.state.mouseDifference < -5 ){
			let momentum = 1
			if(Date.now() - this.state.startTime < 1000)
			this.changeSelectedAsset( -Math.floor( this.state.mouseDifference / 152 ) * 2 + this.state.selectedId, 0, true );
		}
	}
	
	touchMove( event ){
		if( this.state.mouseDown == true ){
			let mouseDifference = event.touches[0].clientX - this.state.initMousePos;
			this.adjustPosition( -Math.floor( mouseDifference / 152 ) + this.state.selectedId );
			this.setState({ mouseDifference: mouseDifference });
			
		}
	}
	
	touchLeave(){
		this.setState({ mouseDown: false, mouseDifference: 0 });
	}
	
	changeSelectedAsset( id, assetId = "", sliding = false ){
		if( this.state.mouseDifference < 5 && this.state.mouseDifference > -5 ){
			console.log('set to id ' + assetId + ' , ' + id);
			this.adjustPosition( id ); 
			this.setState({ selectedId: id, menuClickedOnce: true });
		} else if ( sliding == true ){
			console.log( 'test ' + id ) ;
			this.adjustPosition( id ); 
			this.setState({ selectedId: id, menuClickedOnce: true });
		}
	}
	
	hideMenu(){
		this.setState({ menuTop: "-190px" });
	}
	
	showMenu(){
		this.setState({ menuTop: "20px" });
	}
	
	showMenuHideOption(){
		if( this.state.menuTop == "20px" ){
			return(
				<div className="hide-show-menu" onClick={ this.hideMenu }>
					Hide Menu
				</div>
			);
		} else {
			return(
				<div className="hide-show-menu" onClick={ this.showMenu }>
					Show Menu
				</div>
			);
		}
	}
	
	setInitialSelected( index, key ){
		if( this.state.menuClickedOnce == false ){
			return this.state.initMenuSelected == this.state.menuGot[key]['menuTarget'];
		} else {
			return this.props.primaryMenuItem == index;
		}
	}

	setInitialSlider( index ){
		if( this.initSliderSetVar == false ){
			this.adjustPosition( index );
			this.initSliderSetVar = true;
		}
		return "active";
	}
	
    render(){
        return (
                <div>
                    <nav>
                        <ul id="primary-menu" style={{ marginLeft: this.state.slidePosition, marginTop: this.state.menuTop }} 
							onMouseDown={ this.mouseDown } onMouseUp={ this.mouseUp } onMouseMove={ this.mouseMove } onMouseLeave={ this.mouseLeave }
							onTouchStart={ this.touchDown } onTouchEnd={ this.touchUp } onTouchMove={ this.touchMove } onTouchCancel={ this.touchLeave }
						>
                            {
                                Object.keys(this.state.menuGot).map((key, index) => {
                                    return (
                                        <li key={ index } className={ (this.setInitialSelected( index, key )) ? this.setInitialSlider( index ) : "" } draggable="false" >
                                            <Link to={ this.state.menuGot[key]['menuTarget'] } onClick={() => { this.changePrimaryMenu( index ) } } onMouseUp={ () => { this.changeSelectedAsset( index ) }} draggable="false" ><div>{ this.state.menuGot[key]['menuText'] }</div></Link>
                                        </li>
                                    );
                                })
                            }
                        </ul>  
                    </nav>
					{
						( window.innerWidth < 500 ) ? this.showMenuHideOption() : ""
					}
                </div>
        );
    }
}

const ConnectedMenu = connect( mapStateToProps, mapDispatchToProps )(Menu)

export default ConnectedMenu;