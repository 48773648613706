class defaultAPI{
    constructor(targetPathInput = null, dataInput = null){
        this.targetPath = targetPathInput;
        this.data = dataInput;
    }
    
    RequestData(targetPath, data){
        /* This is where the API will eventually be called */
        
    }
}

export default defaultAPI;