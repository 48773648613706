import React from 'react';
import { connect, useSelector } from 'react-redux';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

import GetOP from '../api/getOP';

const mapStateToProps = (state) => {
    return { selectedTheme: state.theme.selectedTheme };
}

const mapDispatchToProps = (dispatch) => {
    return{
        changeTheme: ( val ) => dispatch({ type: "CHANGETHEME", payload: val })
    }
}

class Options extends React.Component{    
	constructor( props ){
		super(props);
		this.state = { renderItems : <div></div> }
	}
	
	changeTheme( val ){
		this.props.changeTheme( val );
	}
	
	getOptionsContent(){
		let items = new GetOP();
        return items.getData;
	}
	
	componentDidMount(){
        let items = new Promise((resolve, reject) => { 	
			resolve( this.getOptionsContent() ) 
		});
		
		items
        .then( items => { this.setState({ 
			renderItems : <div>{items[0]['description']}</div>,
			});
		})
		.catch( err => { console.log(err) });		
	}
	
	

    render(){
        return (
			<div className="standard-content-container options-page">
				<MetaTags>
					<title>Options | Tom Rice Portfolio 2022</title>
					<meta id="meta-description" name="description" content="Site options" />
					<meta id="og-title" property="og:title" content="Options | Tom Rice Portfolio 2022" />
				</MetaTags>
               <div className="option-container">
					<p>Currently selected theme: <span>{ this.props.selectedTheme }</span></p>
					<p>Select Theme</p>
					<ul>
						<li onClick={ () => { this.changeTheme( 'dark-standard' ) }}>Dark Standard</li>
						<li onClick={ () => { this.changeTheme( 'dark-alternate' ) }}>Dark Alternate</li>
						<li onClick={ () => { this.changeTheme( 'light-standard' ) }}>Light</li>
						<li onClick={ () => { this.changeTheme( 'light-alternate' ) }}>Light Alternate</li>
						<li onClick={ () => { this.changeTheme( 'eye-sore' ) }}>Eye Sore</li>
					</ul>
				</div>
			</div>
        );
    }
}

const ConnectedOptions = connect( mapStateToProps, mapDispatchToProps )(Options)

export default ConnectedOptions;