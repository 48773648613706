import React from 'react';
import { connect, useSelector } from 'react-redux';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

class Options extends React.Component{    
	constructor( props ){
		super(props);
	}

    render(){
        return (
                <div className="standard-content-container home-page">
					<MetaTags>
						<title>Home | Tom Rice Portfolio 2022</title>
						<meta id="meta-description" name="description" content="Web developer portfolio." />
						<meta id="og-title" property="og:title" content="Home | Tom Rice Portfolio 2022" />
					</MetaTags>
					<p>Welcome to my 2020 web & pixel art portfolio. By sheer coincidence I have developed a new portfolio every 2 years from 2012 - that's 5 different portfolio layouts using different styles, philosophies, and technologies!</p>

					<p>To navigate, just click through the different tabs and it will scroll through. The aim is to make this an easier experience over time, but right now this site is currently a work in progress and there's still things I need (and want) to do to it. You can find the current list by visiting the about page.</p>
                </div>
        );
    }
}

export default Options;