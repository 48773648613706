const inititalState = { primaryMenuItem: 1, secondarymenuItem: 0 };
const addMenuReducer = (state = inititalState, action) => {
  switch (action.type) {
    case "CHANGEPRIMARYMENU": {
      return { ...state, primaryMenuItem: action.payload };
    }
    case "CHANGESECONDARYMENU": {
      return { ...state, age: action.payload };
    }
  }
  return state;
};
export default addMenuReducer;