import defaultAPI from './defaultAPI';

class GetOP extends defaultAPI{
    constructor(targetPathInput = null, dataInput = null){   
        super( targetPathInput, dataInput );
    }
    
    get getData() {	
		return new Promise((resolve, reject) => {
			fetch("https://tjtrice.co.uk:9000/GetOP")
			.then(res => res.json())
			.then(
				(result) => {
					resolve( result );
				},
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
				(error) => {
				  resolve( "error" );
				}
			)
		});
    }

    DummyData(){
        console.log(this.data);
        this.RequestData();
        return [
            { 'itemId': 1, 'image': 'logo.svg', 'previewImage': '/path/to/img', 'date': 20010203, 'title': "title", 'description': "Some description of the item", 'relation': [3, 4], 'category': [1, 3] },
        ];
    }
}

export default GetOP;