import defaultAPI from './defaultAPI';

class GetPA extends defaultAPI{
    constructor(targetPathInput = null, dataInput = null){   
        super( targetPathInput, dataInput );
    }
    
    get getData() {
		return new Promise((resolve, reject) => {
			fetch("https://tjtrice.co.uk:9000/GetPL")
			.then(res => res.json())
			.then(
				(result) => {
					resolve( result );
				},
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
				(error) => {
				  resolve( "error" );
				}
			)
		});
    }
}

export default GetPA;