import React from 'react';
import { useParams } from "react-router-dom";

class BrokenPage extends React.Component{
    render(){
        return(
            <div>
                this is a 404
            </div>
        );
    }
}

export default BrokenPage;